import React, { useEffect } from 'react'
import { domToReact } from 'html-react-parser';
import parse from 'html-react-parser';
import AnimatedLink from '../components/global/animatedLink';
import filterObject from 'filter-obj';
import IframeResizer from 'iframe-resizer-react'

export const parseHTML = (html: string) => parse(html, replaceHTML);

const replaceHTML = {
  replace: domNode => {
    if (domNode.attribs) {
      // Clear the HTML of a lot of extra garbage styles that get added in the CMS
      let acceptableAttributes = [
        "class",
        "id",
        "width",
        "height",
        "href",
        "start",
        'src',
        'Source',
        'onClick',
        'secondParseAnchor',
        'target',
        'rel',
        'title',
      ];
      const newAttrs = filterObject(domNode.attribs, key => acceptableAttributes.includes(key as string));
      
      domNode.attribs = newAttrs;

      switch (domNode.name) {
        case 'a':
          // If linking directly to online banking - setup the correct onclick event
          if (domNode.attribs.href === 'https://ib.bankvic.com.au/bvib/signon/login.aspx') {
            return React.createElement('span', 
              {
                className:'login-anchor',
                onClick: (e) => {
                  e.preventDefault()
                  var newWindow = window.open("https://ib.bankvic.com.au/bvib/signon/login.aspx", 'MVP3', "toolbar=yes,menubar=no,location=no,hotkeys=no,directories=no,scrollbars=yes,resizable=yes,status=yes,personalbar=no,self.resizeTo(screen.availWidth,screen.availHeight)");
                  newWindow?.focus();
                },
                children: domToReact(domNode.children)
              } 
            );
          }
          // Relative anchors use animatedLink
          if (
            !/^http:\/\/|^https:\/\/|^tel|^mailto/.test(domNode.attribs.href) && 
            domNode.attribs.href !== 'get-help/?section=branches-and-atms-get-help-landing'
          ) {
            return (
              <AnimatedLink to={domNode.attribs.href} className={`internal-link rte-item`}>
                {domNode.children[0].data}
              </AnimatedLink>
            )
          }
          break;

        case 'iframe':
          // let youtubeID = YouTubeGetID(domNode.attribs.src);
          // let thumbnail = `https://img.youtube.com/vi/${youtubeID}/0.jpg`;
          let isVideo = domNode.attribs.src.indexOf('youtube') > 0 || domNode.attribs.src.indexOf('vimeo') > 0
          const iframeClass = isVideo ? 'video-container rte-item' : 'iframe-container rte-item'
          if (isVideo) {

            return React.createElement('div', 
              {className: iframeClass}, 
              React.createElement('iframe', domNode.attribs)
            )
          } else {
            return (
              <div className="iframe-container">
                <IframeResizer
                  src={domNode.attribs.src}
                  style={{ width: '1px', minWidth: '100%'}}
                />
              </div>
            )
          }

        case 'table':
          return (
            <div className="table-wrap rte-item">
              <table>
                {domToReact(domNode.children)}
              </table>
            </div>
          )

        case 'img':
          let src = '';
          if (!domNode.attribs.src.includes('?fit=clip&w=813')) {
            src = domNode.attribs.src + '?fit=clip&w=813';
          } else {
            src = domNode.attribs.src;
          }
          return React.createElement(
            'img', 
            {
              className:'content-image rte-item',
              src: src,
              width: domNode.attribs.width,
              height: domNode.attribs.height
            } 
          );

        case 'script':
          return

        default:
          return domNode
      }
    }
  }
};